import { useContext, useMemo } from 'react'

import * as Sentry from '@sentry/browser'

import api from '../api'
import { auth } from '../api/endpoint'
import { FEATURE_INTERCEPTOR } from '../sentry/constants-features'
import AuthContext from '../stores/AuthContext'

const ApiInterceptor = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const { setState, state } = useContext(AuthContext)

  useMemo(() => {
    api.interceptors.response.use(
      async function (request) {
        if (request.config.url !== auth.LOGOUT_END_POINT) {
          // try {
          //   const response = await api.post(auth.REFRESH_TOKEN_END_POINT, {
          //     refreshToken: state.tokens.refresh.token,
          //   });
          //   setState({ ...state, tokens: response.data });
          // } catch (err) {
          //   // setState(null);
          // }
        }
        return request
      },
      function (error) {
        return Promise.reject(error)
      }
    )

    // Add a request interceptor
    api.interceptors.request.use((config) => {
      const token = state?.tokens?.access.token
      if (token && config.headers)
        config.headers.Authorization = `Bearer ${token}`

      if (
        !token &&
        config.url !== auth.LOGIN_END_POINT &&
        config.url !== auth.REFRESH_TOKEN_END_POINT &&
        config.url !== auth.LOGOUT_END_POINT
      ) {
        Sentry.withScope(function (scope) {
          scope.setTag('feature', `${FEATURE_INTERCEPTOR}`)
          Sentry.captureException(new Error('Token is null or undefined.'))
        })
      }

      return config
    })
  }, [state?.tokens?.access])

  return children
}

export default ApiInterceptor
