import { Box, Text, UseCheckboxProps } from '@chakra-ui/react'
import { useState } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

// Types
type ComponentType = React.FC<
  React.InputHTMLAttributes<HTMLInputElement> &
    UseCheckboxProps & {
      register?: UseFormRegisterReturn
      children?: React.ReactNode
      isError?: boolean
      isCheckedUpdate?: (isChecked: boolean) => void
    }
>

const StyledCheckbox: ComponentType = ({
  register,
  isError,
  children,
  isChecked = false,
  isCheckedUpdate = () => {},
  ...rest
}) => {
  const styles = {
    outline: isError && !isChecked && '1px solid red',
    content: "' '",
    position: 'absolute',
    display: 'flex',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    background: 'red',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
    backgroundColor: isChecked ? '#45221D' : '#fff',
    border: 'none',
    boxShadow:
      'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;',
  }

  return (
    <Box as="label">
      <input
        style={{ position: 'absolute', opacity: 0 }}
        type="checkbox"
        {...register}
        {...rest}
        // Hacked due to react hook form issue, not reading the changed value
        onChange={(e) => isCheckedUpdate(e.target.checked)}
      />
      {/* TODO: determine how to handle after effects for tailwind */}
      <Text
        cursor="pointer"
        fontFamily="Signifier Regular !important"
        fontWeight="400 !important"
        fontSize="14px !important"
        lineHeight="19px !important"
        letterSpacing="normal !important"
        textAlign="left"
        color="#45221D !important"
        listStyleType="square"
        position="relative"
        paddingLeft="28px"
        _after={styles}
      >
        {children}
      </Text>
    </Box>
  )
}

export default StyledCheckbox
