// Define user action string constants here. Use this to compose the first string argument in Sentry method calls
export const ACTION_SESSION_UPDATING_PREFERENCES = 'updating preferences'
export const ACTION_SESSION_CREATING_SESSION = 'creating session'
export const ACTION_SESSION_STATE_CHANGE = 'session state change'
export const ACTION_SESSION_UPDATING_TRACK = 'track updating'
export const ACTION_SESSION_ENDING_SESSION = 'terminating session'
export const ACTION_AUTHCONTEXT_INIT = 'authentication context intialization'


export const ACTION_ACCOUNT_GET_USER_INFO = 'user info'
export const ACTION_ACCOUNT_SIGN_OUT = 'sign out'
export const ACTION_ACCOUNT_UPDATING_USER_INFO = 'updating user info'
export const ACTION_ACCOUNT_INVITE_FRIEND = 'invite friend'
export const ACTION_ACCOUNT_REMOVE_FRIEND = 'remove friend'
export const ACTION_ACCOUNT_SESSION_HISTORY = 'session history'

export const ACTION_BEDS_GET_LIST = 'retrieve Tune For Home beds'
export const ACTION_BEDS_REGISTER_BED = 'register bed'
