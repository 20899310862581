import { Image } from '@chakra-ui/react'
import type { ImageProps } from '@chakra-ui/react'
import Link from 'next/link'

const Logo = ({
  isBlack = false,
  width = '120px',
}: {
  isBlack?: boolean
} & ImageProps) => {
  return (
    <Link href="/">
      <a>
        <Image
          alt="Logo"
          src={
            isBlack
              ? '/images/logo/tune_logo_burgundy.png'
              : '/images/logo/tune_logo_bone.png'
          }
        />
      </a>
    </Link>
  )
}

export default Logo
