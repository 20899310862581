import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Scto Grotesk A';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('/fonts/scto-grotesk-a/scto-grotesk-a-bold.woff') format('woff'),
        url('/fonts/scto-grotesk-a/scto-grotesk-a-bold.ttf') format('truetype'),
        url('/fonts/scto-grotesk-a/Scto-Grotesk-A-Bold.otf') format('opentype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      
      @font-face {
        font-family: 'Signifier Regular';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/fonts/signifier/signifier-web-regular.woff2') format('woff2'),
          url('/fonts/signifier/SignifierApp-Regular.ttf') format('truetype'),
          url('/fonts/Signifier/Signifier-Regular.otf') format('opentype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
  
      @font-face {
        font-family: 'Signifier Regular';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url('/fonts/signifier/signifier-web-regular-italic.woff2') format('woff2'),
          url('/fonts/signifier/SignifierApp-RegularItalic.ttf') format('truetype'),
          url('/fonts/Signifier/Signifier-RegularItalic.otf') format('opentype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      @font-face {
        font-family: 'Signifier Light';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url('/fonts/signifier/signifier-web-light.woff2') format('woff2'),
          url('/fonts/Signifier/SignifierApp-Light.ttf') format('truetype')
          url('/fonts/Signifier/Signifier-Light.otf') format('opentype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }

      @font-face {
        font-family: 'Signifier Light Italic';
        font-style: italic;
        font-weight: 300;
        font-display: swap;
        src: url('/fonts/signifier/signifier-web-light-italic.woff2') format('woff2'),
          url('/fonts/Signifier/SignifierApp-LightItalic.ttf') format('truetype'),
          url('/fonts/Signifier/Signifier-LightItalic.otf') format('opentype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }

      @font-face {
        font-family: 'Signifier Extralight';
        font-style: normal;
        font-weight: 200;
        font-display: swap;
        src: url('/fonts/Signifier/signifier-web-extralight.woff2') format('woff2'),
        url('/fonts/Signifier/Signifier-Extralight.otf') format('opentype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      
      @font-face {
        font-family: 'Signifier Extralight';
        font-style: italic;
        font-weight: 200;
        font-display: swap;
        src: url('/fonts/Signifier/signifier-web-extralight-italic.woff2') format('woff2'),
        url('/fonts/Signifier/Signifier-ExtralightItalic.otf') format('opentype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
     
      @font-face {
        font-family: 'Soya Black';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('/fonts/soya/Soya-Black.oft') format("opentype");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }

      @font-face {
        font-family: 'Soya Medium';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('/fonts/soya/Soya-Medium.oft') format("opentype");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }

      
      `}
  />
)

export default Fonts
