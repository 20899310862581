import {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
} from 'react'
import type { FC, ReactNode } from 'react'
import Cookie from 'js-cookie'
import * as Sentry from '@sentry/browser'
import { FEATURE_AUTHCONTEXT } from '../../sentry/constants-features'
import { ACTION_AUTHCONTEXT_INIT } from '../../sentry/constants-actions'
import type { Bed, User } from '../../types/'

interface ContextType {
  setState: Dispatch<SetStateAction<Record<any, any> | null>>
  state: Record<any, any>
  clearUserState: () => void
}

const AuthContext = createContext<ContextType>({
  state: {},
  setState: () => null,
  clearUserState: () => null,
})

type AuthState = {
  adminKioskMode: boolean
  adminConnectedBed: string | null
  selectedBed: Bed | null
  loading: boolean
  tokens?: {
    access: {
      token: string
    }
    refresh: {
      token: string
    }
  }
  user?: User
}

export const AuthContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, setState] = useState<Partial<AuthState> | null>({
    loading: true,
  })
  const clearUserState = () => {
    Cookie.remove('AuthState')
    setState({ loading: true })
  }
  const mergeState = (newState: Record<any, any>) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }))
  }
  const context = { state, setState: mergeState, clearUserState }

  useEffect(() => {
    try {
      const cookies = Cookie.get('AuthState')
      const parsedCookie = cookies ? JSON.parse(cookies) : null
      const adminKioskMode = localStorage.getItem('kioskMode') === 'true'
      const adminConnectedBedUnparsed =
        localStorage.getItem('adminConnectedBed') || null
      const adminConnectedBed = adminConnectedBedUnparsed
        ? JSON.parse(adminConnectedBedUnparsed)
        : null
      const selectedBedUnparsed = localStorage.getItem('selectedBed') || null
      const selectedBed = selectedBedUnparsed
        ? JSON.parse(selectedBedUnparsed)
        : null

      setState({
        loading: false,
        user: parsedCookie?.user,
        tokens: parsedCookie?.tokens,
        adminKioskMode,
        adminConnectedBed,
        selectedBed,
      })
    } catch (e) {
      console.error(
        'There was a problem refreshing values from local storage:',
        e
      )
      Sentry.withScope(function (scope) {
        scope.setTag('feature', `${FEATURE_AUTHCONTEXT}`)
        scope.setTag('action', `${ACTION_AUTHCONTEXT_INIT}`)
        Sentry.captureMessage(
          'There was a problem refreshing values from local storage'
        )
      })
    }
  }, [])

  useEffect(() => {
    if (state?.user && state?.tokens) {
      // Set a cookie that expires in 1 week
      Cookie.set(
        'AuthState',
        JSON.stringify({ user: state?.user, tokens: state?.tokens }),
        { expires: 30 }
      )
    }
  }, [state])

  return (
    <AuthContext.Provider value={context as any}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
