import React, { Fragment, useState } from 'react'
import { Box, Center, HStack, useRadio, useRadioGroup } from '@chakra-ui/react'

interface PropsT {
  name: string
  options: string[]
}

const RadioCard: React.FC = (props: any) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label">
      <input {...input} />
      <Center
        {...checkbox}
        cursor="pointer"
        height="50px"
        minWidth="150px"
        borderRadius="25px"
        textTransform="uppercase"
        color="rgba(69,34,29,0.5)"
        fontWeight="bold"
        fontSize="12px"
        fontFamily="Scto Grotesk A"
        _checked={{
          bg: '#45221D',
          color: '#EDE7E1',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
      >
        {props.children}
      </Center>
    </Box>
  )
}

const RadioGroup: React.FC<PropsT> = ({ options, name }) => {
  const [selectedValueIndex, setSelectedValueIndex] = useState(0)
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue: options[0],
    onChange: (e) => setSelectedValueIndex(options.indexOf(e)),
  })

  const group = getRootProps()

  return (
    <HStack {...group} border="1px solid #D4CCCB" borderRadius="25px">
      {options.map((value: string, index: number) => {
        const radio = getRadioProps({ value })
        return (
          <Fragment key={index}>
            <RadioCard {...radio}>{value}</RadioCard>
            {index !== options.length - 1 && (
              <Box
                width="1px"
                bg={
                  index !== selectedValueIndex - 1 &&
                  index !== selectedValueIndex
                    ? '#D4CCCB'
                    : 'transparent'
                }
                height="38px"
              />
            )}
          </Fragment>
        )
      })}
    </HStack>
  )
}

export default RadioGroup
