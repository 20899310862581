// export const maintenanceMode = process.env.MAINTENANCE_MODE;
export const endpoint = process.env.NEXT_PUBLIC_SERVER_URL
// Stripe test : pk_test_cgQQOdA5SHqQK8eQjjgWvTFE
export const STRIPEKEY = process.env.NEXT_PUBLIC_STRIPE_KEY
export const maintenanceMode = false
// 'https://tunestudio-backend.herokuapp.com/api/v1.0';
// export const endpoint = 'https://tunestudio-backend-stage.herokuapp.com/api/v1.0';

// Authentication Endpoints
export const auth = {
  REGISTER_END_POINT: '/auth/register',
  LOGIN_END_POINT: '/auth/login',
  LOGOUT_END_POINT: '/auth/logout',
  FORGOT_PASSWORD_END_POINT: '/auth/forgot-password',
  RESET_PASSWORD_END_POINT: '/auth/reset-password',
  REFRESH_TOKEN_END_POINT: '/auth/refresh-tokens',
  EMAIL_VERIFY_ENDPOINT: 'auth/verify-email',
  LOGIN_KIOSK_ENDPOINT: 'auth/login/kiosk',
}

export const checkout = {
  CART_SIGNEDIN_ENDPOINT: '/cart',
  CART_GUEST_ENDPOINT: '/cart/guest',
  CART_PAYMENTINTENT_ENDPOINT: '/paymentIntent',
  CART_GUEST_ORDER_ENDPOINT: '/order/guest',
}

export const user = {
  USER_ENDPOINT: '/user',
}

export const bed = {
  BED_ENDPOINT: '/bed',
  REGISTER_BED_ENDPOINT: '/bed/register',
}

export const company = {
  COMPANY_ENDPOINT: '/company',
}
