import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

// 2. Update the breakpoints as key-value pairs
const breakpoints = createBreakpoints({
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
})

const theme = extendTheme({
  breakpoints,
  fonts: {
    heading: 'Signifier Regular',
    body: 'Signifier Regular',
  },
  components: {
    Button: {
      baseStyle: {
        rounded: 'none',
        textTransform: 'uppercase',
        fontFamily: 'Scto Grotesk A',
      },
      variants: {
        principal: {
          bgColor: 'transparent',
          fontSize: ['11px', '11px', '13px'],
          border: '1px solid #CC724F',
          _hover: {
            bgColor: '#CC724F',
            color: 'white',
          },
        },
      },
    },

    Heading: {
      baseStyle: {
        color: '#45221D',
      },
      variants: {
        white: {
          color: '#EDE7E1',
        },
      },
    },
    Text: {
      baseStyle: {
        color: '#45221D',
      },
      variants: {
        white: {
          color: '#EDE7E1',
        },
      },
    },
    Link: {
      baseStyle: {
        fontFamily: 'Scto Grotesk A',
      },
    },
  },
})

export default theme
