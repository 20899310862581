var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"aa8b86f26a705098eddfc08df7adb58636fd2a52"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://c54247d774cb4e1692c7d63adcc0b5a5@o1283678.ingest.sentry.io/6493535',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,
  // We do not want any session videos that don't involve an error
  replaysSessionSampleRate: 0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.Replay({
      // Prevent revealing personally identifiable information
      maskAllText: false,
      networkDetailAllowUrls: [
        'https://tune-service-production-080b.up.railway.app/v1',
      ],
      networkRequestHeaders: ['X-Customer-Header'],
      networkResponseHeaders: ['X-Customer-Header'],
    }),
  ],
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        labelSubmit: 'Submit report',
        successMessage:
          'Thank you, your report has been sent to the TUNE Technology team. If more information is needed, a member of our team may reach out to the email provided. If you need immediate assistance, you can text the support team at (254) 277-4412 (US).',
      })
    }
    return event
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
