import React from 'react'
import { Input } from '@chakra-ui/react'
import { UseFormRegisterReturn } from 'react-hook-form'

interface IStyledInput {
  placeholder: string
  width?: string
  type?: string
  register?: UseFormRegisterReturn
  name?: string
  onChange?: (args: any) => void
  isError?: boolean
  disabled?: boolean
  marginRight?: string
}

const StyledInput: React.FC<IStyledInput> = ({
  placeholder,
  width,
  type = 'text',
  register,
  name,
  disabled = false,
  marginRight = '0px',
  ...rest
}) => {
  const defaultWidth = '100%'
  return (
    <Input
      width={!!width ? width : defaultWidth}
      height="54px"
      fontSize="13px"
      lineHeight="13px"
      letterSpacing="0.93px"
      marginBottom="10px"
      marginRight={marginRight}
      border="1px solid #D4CCCB"
      borderColor="#D4CCCB"
      disabled={disabled}
      _placeholder={{
        color: '#B5A7A5',
        textTransform: 'uppercase',
      }}
      fontFamily="Scto Grotesk A"
      outline="none"
      borderRadius="0"
      fontWeight="bold"
      _focus={{
        outline: 'none',
      }}
      placeholder={placeholder}
      type={type}
      name={name}
      {...register}
      {...rest}
    />
  )
}

export default StyledInput
