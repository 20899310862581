import React from 'react'
import { Image as ChakraImage, Box } from '@chakra-ui/react'
import Image from 'next/image'

interface PropsT {
  placeholder: string
  src: string
  alt?: string
}

const LazyImage = ({ placeholder, src, alt }: PropsT) => {
  return (
    <Box position="relative" width="100%" height="100%" zIndex={1}>
      <Image
        alt={alt ?? ''}
        src={src}
        placeholder="blur"
        blurDataURL={placeholder}
        layout="fill"
        objectFit="cover"
      />
      {placeholder && (
        <ChakraImage
          alt={alt}
          src={placeholder}
          height="100%"
          width="100%"
          objectFit="cover"
          position="relative"
          visibility="hidden"
        />
      )}
    </Box>
  )
}

export default LazyImage
