import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/autoplay'
import '../styles/marquee.scss'
import '../styles/globals.css'

import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

import { ChakraProvider } from '@chakra-ui/react'
import va from '@vercel/analytics'
import { Analytics } from '@vercel/analytics/react'

import { ApiInterceptor } from '../components'
import { AuthContextProvider } from '../stores/AuthContext'
import Fonts from '../theme/font'
import theme from '../theme/theme'
import { FacebookPixel } from '../utils/FacebookPixel'
import { LinkedInInsight } from '../utils/LinkedInInsight'

const allowedKioskRoutes = ['/start-session']

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()
  const currentPath = router.pathname
  const queryClient = new QueryClient()

  useEffect(
    function rerouteDisallowedKioskRoute() {
      const isKioskMode = localStorage?.getItem('kioskMode') || false

      if (isKioskMode && !allowedKioskRoutes.includes(currentPath)) {
        router.push('/start-session')
      }
    },
    [router.events]
  )

  return (
    <>
      <Head>
        <title>TUNE</title>
        <meta title="Performance tech for your nervous system" />
        <meta
          name="description"
          content="Recalibrate your nervous system in 15 minutes with medical-grade vibrations scientifically proven to reduce stress, improve sleep and maintain your edge."
        />
        <meta name="theme-color" content="#EDE7E1" />
      </Head>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <AuthContextProvider>
            <ApiInterceptor>
              <>
                <FacebookPixel />
                <LinkedInInsight />
                <Fonts />
                <Component {...pageProps} />
                <Analytics />
              </>
            </ApiInterceptor>
          </AuthContextProvider>
        </ChakraProvider>
      </QueryClientProvider>
    </>
  )
}

export default MyApp
