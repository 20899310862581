import { Box } from '@chakra-ui/react'

const ProgressBar: React.FC<{ progress: string }> = ({ progress }) => {
  return (
    <Box width="100%">
      <Box height="8px" width={progress} bg="#CC724F" />
    </Box>
  )
}
export default ProgressBar
