import { Button as ChakraButton } from '@chakra-ui/react'

const $Button = (props: any) =>
  props.variant === 'secondary' ? (
    <ChakraButton
      border="1px solid #CC724F"
      color="#CC724F"
      width="182px"
      height="52px"
      {...props}
      variant="principal"
    />
  ) : (
    <ChakraButton
      width="100%"
      height="44px"
      backgroundColor="#CC724F"
      fontFamily="Scto Grotesk A"
      fontSize="14px"
      lineHeight="14px"
      letterSpacing="1px"
      textAlign="center"
      color="#FFFFFF"
      outline="none"
      _hover={{
        backgroundColor: '#CC724F',
      }}
      _focus={{
        outline: 'none',
        backgroundColor: '#CC724F',
      }}
      {...props}
      variant={undefined}
    />
  )

export default $Button
